import { Book, Page } from '../../domain/entity/book';

export interface BooksReducerState {
  books: Book[];
  pages: Page[];
  isLoading: boolean;
}

export interface IActionBooks {
  type: BOOKS_ACTIONS;
  books: Book[];
  pages: Page[];
}

export enum BOOKS_ACTIONS {
  LOADING = 'BOOKS_ACTIONS/LOADING',
  FINISH_LOADING = 'BOOKS_ACTIONS/FINISH_LOADING',
  FETCH_BOOKS = 'BOOKS_ACTIONS/FETCH_BOOKS',
  SET_BOOKS = 'BOOKS_ACTIONS/SET_BOOKS',
  FETCH_PAGES = 'BOOKS_ACTIONS/FETCH_PAGES',
  SET_PAGES = 'BOOKS_ACTIONS/SET_PAGES',
  SET_BOOK_STATUS = 'BOOKS_ACTIONS/SET_BOOK_STATUS',
  ADD_BOOK_COMMENT = 'BOOKS_ACTIONS/ADD_BOOK_COMMENT',
}
