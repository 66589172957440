import { useLocation } from 'react-router-dom';
import { isNull } from 'lodash';

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const useNormalizedPag = () => {
  let query = useQuery();
  const pag = query.get('pag');
  return isNull(pag) ? 1 : parseInt(pag, 10);
};
