import { isArray } from 'lodash';

import { FetchBooksAPIResponse, FetchPagesByBookIDAPIResponse } from '../../domain/APIResponses';
import { Book, Page } from '../../domain/entity/book';
import { AxiosResponse } from 'axios';

export const convertFetchBookAPIResponseToBooks = (
  data: AxiosResponse<FetchBooksAPIResponse>,
): Book[] => {
  const payload = data.data;
  if (!isArray(payload.list)) return [];

  return payload.list.map((entry) => ({
    index: parseInt(entry.book.id, 10),
    validationStatus: entry.book.status,
    scanStatus: entry.book.scanned_status,
    expectedPageCount: entry.book.metadata.expected_num_pages,
    actualPageCount: entry.book.metadata.actual_num_pages,
    scannedAt: new Date(entry.book.scanned_at),
    reviewedAt: new Date(entry.book.reviewed_at),
  }));
};

export const convertFetchPagesAPIResponseToPages = (
  data: AxiosResponse<FetchPagesByBookIDAPIResponse>,
): Page[] => {
  const payload = data.data;
  if (!isArray(payload.list)) return [];

  return payload.list.map((entry) => ({
    index: entry.id,
    name: `${entry.number}`,
    url: entry.file_name,
  }));
};
