import { put, takeEvery, all, call } from 'redux-saga/effects';
import { BOOKS_ACTIONS } from './types';
import { finishLoadingAction, setBooksAction, startLoadingAction, setPagesAction } from './actions';
import { Book, Page } from '../../domain/entity/book';
import {
  addBookCommentAPI,
  fetchBooksAPI,
  fetchPagesByBookIdAPI,
  setBookStatusAPI,
} from '../../api/bookAPI';
import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';

import { FetchBooksAPIResponse, FetchPagesByBookIDAPIResponse } from '../../domain/APIResponses';
import {
  convertFetchBookAPIResponseToBooks,
  convertFetchPagesAPIResponseToPages,
} from './normalize';

function* fetchBooksSaga(action: AnyAction) {
  yield put(startLoadingAction());
  const response: AxiosResponse<FetchBooksAPIResponse> = yield call(fetchBooksAPI, action.pag);
  const books: Book[] = yield call(convertFetchBookAPIResponseToBooks, response);
  yield put(setBooksAction(books));
  yield put(finishLoadingAction());
}

function* fetchPagesSaga(action: AnyAction) {
  yield put(startLoadingAction());
  const response: AxiosResponse<FetchPagesByBookIDAPIResponse> = yield call(
    fetchPagesByBookIdAPI,
    action.bookId,
    action.pag,
  );
  const pages: Page[] = yield call(convertFetchPagesAPIResponseToPages, response);
  yield put(setPagesAction(pages));
  yield put(finishLoadingAction());
}

function* setBookStatusSaga(action: AnyAction) {
  yield put(startLoadingAction());
  yield call(setBookStatusAPI, action.bookId.toString(), action.status);
  yield put(finishLoadingAction());
}

function* addBookCommentSaga(action: AnyAction) {
  yield put(startLoadingAction());
  yield call(addBookCommentAPI, action.bookId.toString(), action.comment);
  yield put(finishLoadingAction());
}

function* watchFetchBooksAsync() {
  yield takeEvery(BOOKS_ACTIONS.FETCH_BOOKS, fetchBooksSaga);
}

function* watchFetchPagesAsync() {
  yield takeEvery(BOOKS_ACTIONS.FETCH_PAGES, fetchPagesSaga);
}

function* watchSetBookStatusAsync() {
  yield takeEvery(BOOKS_ACTIONS.SET_BOOK_STATUS, setBookStatusSaga);
}

function* watchAddBookCommentAsync() {
  yield takeEvery(BOOKS_ACTIONS.ADD_BOOK_COMMENT, addBookCommentSaga);
}

export function* booksSaga() {
  yield all([
    watchFetchBooksAsync(),
    watchFetchPagesAsync(),
    watchSetBookStatusAsync(),
    watchAddBookCommentAsync(),
  ]);
}
