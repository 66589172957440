import { BOOKS_ACTIONS } from './types';
import { Book, Page } from '../../domain/entity/book';

export const fetchBooksAction = (pag: number = 1) => ({
  type: BOOKS_ACTIONS.FETCH_BOOKS,
  pag,
});

export const fetchPagesAction = (bookId: number, pag: number = 1) => ({
  type: BOOKS_ACTIONS.FETCH_PAGES,
  bookId,
  pag,
});

export const setBooksAction = (books: Book[]) => ({
  type: BOOKS_ACTIONS.SET_BOOKS,
  books,
});

export const setPagesAction = (pages: Page[]) => ({
  type: BOOKS_ACTIONS.SET_PAGES,
  pages,
});

export const startLoadingAction = () => ({
  type: BOOKS_ACTIONS.LOADING,
});

export const finishLoadingAction = () => ({
  type: BOOKS_ACTIONS.FINISH_LOADING,
});

export const setBookStatusAction = (bookId: number, status: 'ok' | 'rescan') => ({
  type: BOOKS_ACTIONS.SET_BOOK_STATUS,
  bookId,
  status,
});

export const addBookCommentAction = (bookId: number, comment: string) => ({
  type: BOOKS_ACTIONS.ADD_BOOK_COMMENT,
  bookId,
  comment,
});
