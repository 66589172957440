import React, { FC } from 'react';
import { CPagination, CPaginationItem } from '@coreui/react';
import { Link } from 'react-router-dom';
import { isNull } from 'lodash';
import { useQuery } from '../hooks';

export const BookPagination: FC<{
  numOfPags?: number;
}> = ({ numOfPags = 10 }) => {
  let query = useQuery();
  const pag = query.get('pag');
  const currentPag = isNull(pag) ? 1 : parseInt(pag, 10);
  const numNode = [];
  for (let i = 0; i < numOfPags; i++) {
    numNode.push(
      <CPaginationItem>
        <Link to={`?pag=${i + 1}`}>{i + 1}</Link>
      </CPaginationItem>,
    );
  }

  return (
    <CPagination aria-label="Page navigation example">
      <CPaginationItem disabled={currentPag === 1}>
        <Link to={`?pag=${currentPag - 1}`}>Previous</Link>
      </CPaginationItem>
      {numNode}
      <CPaginationItem>
        <Link to={`?pag=${currentPag + 1}`}>Next</Link>
      </CPaginationItem>
    </CPagination>
  );
};
