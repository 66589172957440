import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { BookListPage } from './pages/BookListPage';
import { PageListPage } from './pages/PageListPage';

export const Navigation = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <BookListPage />
        </Route>
        <Route path="/pages/:bookId" exact>
          <PageListPage />
        </Route>
      </Switch>
    </Router>
  );
};
