import axios from 'axios';

import {
  AddBookCommentAPIPayload,
  FetchBooksAPIPayload,
  FetchPagesByBookIDAPIPayload,
  SetBookStatusIDAPIPayload,
} from '../domain/APIPayloads';

const axiosConfig = {
  baseURL: process.env.REACT_APP_API_URL,
};

export const fetchBooksAPI = (page = 1) => {
  const payload: FetchBooksAPIPayload = {
    order_by: 'status',
    order_type: 'desc',
    page,
    per_page: 50,
  };

  return axios.post('/poi-page-scanner/book/list', payload, axiosConfig);
};

export const fetchPagesByBookIdAPI = (id: string, page = 1) => {
  const payload: FetchPagesByBookIDAPIPayload = {
    book_id: String(id) || '1',
    page,
    per_page: 100,
  };

  return axios.post('/poi-page-scanner/page/list', payload, axiosConfig);
};

export const setBookStatusAPI = (book_id: string, status: 'ok' | 'rescan') => {
  const payload: SetBookStatusIDAPIPayload = {
    book_id,
    status,
  };

  return axios.post('/poi-page-scanner/page/change-status', payload, axiosConfig);
};

export const addBookCommentAPI = (book_id: string, comment: string) => {
  const payload: AddBookCommentAPIPayload = {
    book_id,
    comment,
  };

  return axios.post('/poi-page-scanner/book/comment', payload, axiosConfig);
};
