import React, { CSSProperties, FC, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  CContainer,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CSpinner,
  CButton,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { getBooks, getIsLoading } from '../ducks/booksStore/selectors';
import {
  fetchBooksAction,
  setBookStatusAction,
  addBookCommentAction,
} from '../ducks/booksStore/actions';
import { Book } from '../domain/entity/book';
import { BookPagination } from '../components/BookPagination';
import { useNormalizedPag } from '../hooks';
import { Header } from '../components/Header';

const useFetchBooksEffct = (page: number) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBooksAction(page));
  }, [dispatch, page]);
};

const stylesByStatus: Record<Book['validationStatus'], CSSProperties> = {
  new: {},
  success: { backgroundColor: '#ced2d8' },
  warning: { backgroundColor: '#f9b115' },
  error: { backgroundColor: '#e55353' },
};

const BookComment: FC<{ bookId: number }> = ({ bookId }) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const dispatch = useDispatch();

  return (
    <CTableDataCell>
      <textarea ref={ref} />
      <CButton
        color="primary"
        onClick={() => {
          const text = ref.current?.value;
          if (text) dispatch(addBookCommentAction(bookId, text));
        }}
      >
        Submit
      </CButton>
    </CTableDataCell>
  );
};

export const BookListPage = () => {
  const books = useSelector(getBooks);
  const isLoading = useSelector(getIsLoading);
  const normalizedPag = useNormalizedPag();
  useFetchBooksEffct(normalizedPag);
  const dispatch = useDispatch();

  return (
    <CContainer fluid>
      <Header>Books from list page # {normalizedPag}</Header>
      <BookPagination />

      <CTable>
        <CTableHead>
          <CTableRow>
            {/* @ts-ignore */}
            <CTableHeaderCell scope="col">#</CTableHeaderCell>
            {/* @ts-ignore */}
            <CTableHeaderCell scope="col">Review Date</CTableHeaderCell>
            {/* @ts-ignore */}
            <CTableHeaderCell scope="col">Scan Date</CTableHeaderCell>
            {/* @ts-ignore */}
            <CTableHeaderCell scope="col">Pages(Actual/Expected)</CTableHeaderCell>
            {/* @ts-ignore */}
            <CTableHeaderCell scope="col">Scan Status</CTableHeaderCell>
            {/* @ts-ignore */}
            <CTableHeaderCell scope="col">Artifact Status</CTableHeaderCell>
            {/* @ts-ignore */}
            <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
            {/* @ts-ignore */}
            <CTableHeaderCell scope="col">Comments</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {isLoading && (
            <CTableRow>
              {/* @ts-ignore */}
              <CTableHeaderCell scope="row" colspan="2">
                <CSpinner />
              </CTableHeaderCell>
            </CTableRow>
          )}

          {books.map((book, idx) => (
            <CTableRow style={stylesByStatus[book.validationStatus]} key={`book-${idx}`}>
              {/* @ts-ignore */}
              <CTableHeaderCell scope="row">
                <Link to={`/pages/${book.index}`}>Book {book.index}</Link>
              </CTableHeaderCell>
              <CTableDataCell>{book.reviewedAt.toUTCString()}</CTableDataCell>
              <CTableDataCell>{book.scannedAt.toUTCString()}</CTableDataCell>
              <CTableDataCell>
                {book.actualPageCount}/{book.expectedPageCount}
              </CTableDataCell>
              <CTableDataCell>{book.scanStatus}</CTableDataCell>
              <CTableDataCell>{book.validationStatus}</CTableDataCell>
              <CTableDataCell>
                <CButton
                  color="primary"
                  onClick={() => dispatch(setBookStatusAction(book.index, 'rescan'))}
                >
                  Rescan
                </CButton>
                <CButton
                  color="danger"
                  onClick={() => dispatch(setBookStatusAction(book.index, 'ok'))}
                >
                  Delete
                </CButton>
              </CTableDataCell>
              <BookComment bookId={book.index} />
            </CTableRow>
          ))}
        </CTableBody>
      </CTable>

      <BookPagination />
    </CContainer>
  );
};
