import React, { useEffect, useState } from 'react';
import { isUndefined } from 'lodash';
import {
  CContainer,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getBookByID, getIsLoading, getPages } from '../ducks/booksStore/selectors';
import { fetchPagesAction } from '../ducks/booksStore/actions';
import { Page } from '../domain/entity/book';
import { useNormalizedPag } from '../hooks';
import { Header } from '../components/Header';
import { BookPagination } from '../components/BookPagination';

const useFetchPagesEffct = (bookId: string, pag: number) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPagesAction(parseInt(bookId, 10), pag));
  }, [dispatch, bookId, pag]);
};

const Row = (page: Page) => {
  const [url, setUrl] = useState('');
  const albumBucketName = process.env.REACT_APP_AWS_BUCKET_NAME;
  if (!isUndefined(page)) {
    var params = {
      Bucket: albumBucketName,
      Key: page.url,
    };

    // Create a new service object
    const s3 = new window.AWS.S3({
      apiVersion: '2006-03-01',
      params: { Bucket: albumBucketName },
    });

    s3.getSignedUrl('getObject', params, (err: unknown, url: string) => {
      setUrl(url);
    });
  }

  return (
    <CTableRow>
      {/* @ts-ignore */}
      <CTableHeaderCell scope="row">Page {page.name}</CTableHeaderCell>
      <CTableDataCell>
        <a href={url} target="_blank">
          Link
        </a>
      </CTableDataCell>
    </CTableRow>
  );
};

const PAGES_PER_PAGE = 50;

export const PageListPage = () => {
  const pages = useSelector(getPages);
  let { bookId } = useParams<{ bookId: string }>();
  const book = useSelector(getBookByID)(bookId);
  const isLoading = useSelector(getIsLoading);
  const normalizedPag = useNormalizedPag();
  let pageNumb = 1;
  if (!isUndefined(book)) {
    pageNumb = Math.floor(book.actualPageCount / PAGES_PER_PAGE) + 1;
  }

  useFetchPagesEffct(bookId, normalizedPag);

  return (
    <CContainer fluid>
      <Header>Pages list page # {normalizedPag}</Header>
      <BookPagination numOfPags={pageNumb} />

      <CTable>
        <CTableHead>
          <CTableRow>
            {/* @ts-ignore */}
            <CTableHeaderCell scope="col">#</CTableHeaderCell>
            {/* @ts-ignore */}
            <CTableHeaderCell scope="col">Url</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {isLoading && (
            <CTableRow>
              {/* @ts-ignore */}
              <CTableHeaderCell scope="row" colspan="2">
                <CSpinner />
              </CTableHeaderCell>
            </CTableRow>
          )}

          {pages.map((page, idx) => (
            <Row key={idx} {...page} />
          ))}
        </CTableBody>
      </CTable>

      <BookPagination numOfPags={pageNumb} />
    </CContainer>
  );
};
