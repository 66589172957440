import React, { FC } from 'react';
import { CContainer, CHeader, CHeaderBrand } from '@coreui/react';

export const Header: FC = ({ children }) => {
  return (
    <CHeader>
      <CContainer fluid>
        <CHeaderBrand>{children}</CHeaderBrand>
      </CContainer>
    </CHeader>
  );
};
