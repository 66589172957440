// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';

import { Navigation } from './Navigation';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import './styles';

import awsconfig from './aws-exports';

function App() {
  const [isAWSLoaded, setIsAWSLoded] = useState(false);

  useEffect(() => {
    const { AWS } = window;

    const timeout = setTimeout(() => {
      Auth.currentSession()
        .then((res) => {
          const token = res.getIdToken().getJwtToken();
          axios.interceptors.request.use(function (config) {
            config.headers.Authorization = `Bearer ${token}`;

            return config;
          });

          setIsAWSLoded(true);
          AWS.config.region = awsconfig.aws_cognito_region;
          const poolProviderUrl = `cognito-idp.${awsconfig.aws_cognito_region}.amazonaws.com/${awsconfig.aws_user_pools_id}`;
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
            Logins: {
              [poolProviderUrl]: token,
            },
          });

          // var s3 = new AWS.S3({
          //   apiVersion: '2006-03-01',
          //   params: { Bucket: albumBucketName },
          // });
          //
          // s3.listObjects({ Delimiter: '/' }, function (err: any, data: any) {
          //   console.log(err, data);
          //   if (err) {
          //     return alert('There was an error listing your albums: ' + err.message);
          //   } else {
          //     console.log(data);
          //     var albums = data.CommonPrefixes.map(function (commonPrefix: any) {
          //       var prefix = commonPrefix.Prefix;
          //       var albumName = decodeURIComponent(prefix.replace('/', ''));
          //     });
          //     console.log('Result', albums);
          //   }
          // });
        })
        .catch(() => {
          console.log('reject');
        });
    }, 800);

    return () => clearTimeout(timeout);
  });

  return (
    <>
      {isAWSLoaded && <Navigation />}
      <AmplifySignOut />
    </>
  );
}

export default withAuthenticator(App);
