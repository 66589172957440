import { BOOKS_ACTIONS, BooksReducerState, IActionBooks } from './types';

const initialState: BooksReducerState = {
  books: [],
  pages: [],
  isLoading: false,
};

export const booksReducer = (state = initialState, action: IActionBooks) => {
  switch (action.type) {
    case BOOKS_ACTIONS.SET_BOOKS:
      return {
        ...state,
        books: action.books,
      };
    case BOOKS_ACTIONS.SET_PAGES:
      return {
        ...state,
        pages: action.pages,
      };
    case BOOKS_ACTIONS.LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case BOOKS_ACTIONS.FINISH_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
