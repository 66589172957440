import { RootState } from '../../reducers/types';
import { find } from 'lodash';
import { Book } from '../../domain/entity/book';

const getStore = (state: RootState) => state.books;
export const getBooks = (state: RootState) => getStore(state).books;
export const getBookByID = (state: RootState) => (bookID: string) =>
  find(getBooks(state), (item: Book) => {
    return item.index === parseInt(bookID, 10);
  });
export const getPages = (state: RootState) => getStore(state).pages;
export const getPageByID = (state: RootState) => (pageID: string) => {
  const pages = getPages(state);
  return find(pages, (item) => item.index === pageID);
};

export const getIsLoading = (state: RootState) => getStore(state).isLoading;
